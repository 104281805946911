<template>
  <v-dialog max-width="300" v-model="dialog">
    <v-card>
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
      >
        {{ $t("boxes.new_box") }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="box-form"
        @submit.prevent="validateForm('box-form')"
      >
        <v-card-text>
          <v-row class="pt-0 pb-0">
            <v-col cols="12" class="pt-0 pb-2">
              <label style="font-size: 12px">{{ $t("boxes.name") }}</label>
              <v-text-field
                outlined
                dense
                v-model="box.name"
                v-validate="'required|min:1|max:100'"
                :error-messages="errors.collect('box-form.name')"
                color="secondary"
                hide-details
                data-vv-validate-on="blur"
                data-vv-name="name"
              />
            </v-col>
            <v-col
              v-if="this.$store.getters['auth/getSetting']('box_price') == 1"
              class="pt-0 pb-2"
              cols="12"
            >
              <label style="font-size: 12px">{{ $t("boxes.price") }}</label>

              <number-input-with-separators
                outlined
                dense
                hide-details
                :value="box"
                valueKey="price"
                rules="required|is_not:0€"
                ref="money_1"
              ></number-input-with-separators>
            </v-col>
            <v-col
              v-if="this.$store.getters['auth/getSetting']('payment_box') == 1"
              class="pt-0 pb-5"
              cols="12"
            >
              <label style="font-size: 12px">
                {{ $t("boxes.token_payment") }}
              </label>

              <number-input-with-separators
                outlined
                dense
                hide-details
                :value="box"
                valueKey="token_payment"
                rules="required|is_not:0€"
                ref="money_2"
              ></number-input-with-separators>
            </v-col>
          </v-row>

          <v-row class="py-3" justify="center">
            <v-btn
              class="mr-3"
              rounded
              outlined
              style="height: 30px; width: 100px"
              color="primary"
              @click="dialog = false"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mr-0"
              rounded
              elevation="0"
              style="height: 30px; width: 100px !important"
              color="primary"
              type="submit"
              ><v-icon style="margin-right: 5px" size="14px">$save</v-icon
              >{{ $t("save", { name: $t("") }) }}</v-btn
            >
          </v-row>
        </v-card-text>
      </form>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "BoxForm",
  data() {
    return {};
  },
  methods: {
    async validateForm(scope) {
      this.errors.clear();

      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          if (this.actionState == "save") {
            this.saveBox();
          } else {
            this.update();
          }
        } else {
        }
      });
    },
    ...mapActions("boxes", ["addBox", "updateBox"]),
    saveBox() {
      //console.log(this.box);
      this.addBox({
        box: { ...this.box, studio_id: this.$store.state.boxes.studioId },
      }).then((response) => {
        if (response) {
          if (response.success) {
            this.errors.clear();
            this.$alert(this.$t("box_create_success"), "", "");
            this.dialog = false;
            this.$emit("reload");
          } else {
            this.$alert(this.$t("box_" + response.error), "", "");
          }
        }
      });
    },

    update() {
      //console.log(this.box);
      this.updateBox({
        box: this.box,
        id_box: this.box.id,
      }).then((response) => {
        if (response) {
          this.errors.clear();
          this.dialog = false;
          this.$alert(this.$t("box_edit_success"), "", "");
          this.$emit("reload");
        }
      });
    },
  },
  watch: {
    dialog: {
      handler() {
        this.$nextTick(() => {
          if (this.$refs.money_1) this.$refs.money_1.change();
          if (this.$refs.money_2) this.$refs.money_2.change();
        });
      },
      deep: true,
    },
  },
  components: {
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
  },
  computed: {
    ...mapState("boxes", ["boxState", "dialogState", "actionState"]),
    box: {
      get() {
        return this.boxState;
      },
      set(value) {
        this.$store.commit("boxes/SET_BOX", { value });
      },
    },
    dialog: {
      get() {
        return this.dialogState.form;
      },
      set(value) {
        this.$store.commit("boxes/SET_DIALOG", { value, dialog: "form" });
      },
    },
  },
};
</script>

<style lang="sass" scoped>
label
  display: block
  height: 25px
</style>
